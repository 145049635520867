import React from 'react'
import { navigate } from '@reach/router';

import SEO from './../components/templates/seo';
import Section from './../components/atoms/section';
import Heading from './../components/atoms/heading';
import Text from './../components/atoms/text';
import Button from './../components/atoms/button';

export default function NotFound(props) {
    return (
        <>
            <SEO title='Coś poszło nie tak...' />
            <Section sx={{
                width: '100%',
                height: 'auto',
                minHeight: 200,
                flexFlow: 'column nowrap',
                justifyContent: 'space-between'
            }} animation={{
            'data-sal': 'slide-up',
            'data-sal-duration': '600',
            'data-sal-delay': '0',
            'data-sal-easing': 'cubic-bezier(0.165, 0.84, 0.44, 1)'
            }}>
                <Heading as='h1' background_text='Error 404'>Coś poszło nie tak...</Heading>
                <Text textAlign='center'>Ta strona nie istnieje.</Text>
                <Button onClick={() => navigate('/')}>...Strona główna...</Button>
            </Section>
        </>
    )
}